import { useEffect, useState } from 'react';
import './App.css';
import axios, { AxiosResponse } from 'axios';

function App() {
  const [data, setData] = useState<Object>({});

  /*
  const data = {
    252251336: {
      name: "dev-env",
      html_url: "https://github.com/GreenlightMe/dev-env",
      has_sonarqube: false
    },
    95685595: {
      name: "commander-api",
      html_url: "https://github.com/GreenlightMe/commander-api",
      has_sonarqube: true
    }
  }
  */
  useEffect(() => {
    const fetchData = async () => {
      // test
      // const result = await axios.get('https://cuddly-owl-74.loca.lt/github/sonarqube-diff');
      const result = await axios.get('https://testbot.glops.io/github/sonarqube-diff');
      console.log(result.data);

      delete result.data['total_count'];
      setData(result.data);
    }
    fetchData();
  }, []);

  // component that holds the shape of the data
  return (
    <div className="App">
      <table style={{ width: "100%" }}>
        <tbody>

          <tr>
            <th>ID</th>
            <th>name</th>
            <th>url</th>
            <th>sonarqube?</th>
          </tr>
          {Object.entries(data).map(([key, value]) => {
            return (<tr>
              <td>{key}</td>
              <td>{value.name}</td>
              <td><a href={value.html_url}>{value.html_url}</a></td>
              <td>{value.has_sonarqube.toString()}</td>
            </tr>)
          })}
        </tbody>
      </table>
    </div>
  );
}

export default App;
